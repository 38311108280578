html {
  background-color: black;
}

body {
  height: -moz-available;
  height: -webkit-fill-available;
  height: stretch;
}

@media print {
  .no-print, .no-print *
  {
    display: none !important;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 12vmin;
}

.App-header {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  top: 0;
  width: -webkit-fill-available;
  margin: 8px 8px 0 0;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
  z-index: +1;
  pointer-events: none;
}

.App-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  max-width: 100%;
  max-width: -moz-available;
  max-width: -webkit-fill-available;
  max-height: 100%;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  color: white;
}

.App-footer {
  min-height: 4rem;
  min-width: 100%;
  min-width: -moz-available;
  min-width: -webkit-fill-available;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: center;
  font-size: calc(2px + 2vw);
  color: white;
  z-index: +1;
}

.App-detail {
  min-width: 28%;
  text-align: left;
  margin: 0 1vmin;
}

.App-text {
  margin: 0.5vmin 0;
  padding: 0.5vmin 0.5vmin;
  border-top: 1px solid #4cc4dc;
  font-size: calc(1px + 1.7vw);
  text-align: left;
  line-height: 1.2;
  color: white;
}

.App-link {
  color: #6ecfe2;
  font-weight: bold;
  text-decoration: none;
  text-shadow:  -1px 0px 1px rgba(0, 0, 0, 0.3), 0px -1px 1px rgba(255, 255, 255, 0.3), 1px 0px 1px rgba(0, 0, 0, 0.5), 0px 1px 1px rgba(0, 0, 0, 1), 0px 0px 8px rgba(255, 255, 255, 0.7);
}

.App-link:hover {
  color: #ddd255;
}