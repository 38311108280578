.legal {
  text-wrap: pretty;
  text-align: left;
  background-color: var(--amplify-colors-background-tertiary);
  padding: 12pt 12pt 36pt 12pt;
}

@media screen {
  .legal {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}

@media print
{
  font-size: 12pt;
}

@page {
  size: auto;
  margin: 0.5in 0.25in 0.5in 0.25in;
}

.legal p, .legal dd {
  font-size: var(--amplify-font-sizes-medium);
  margin-bottom: 12pt;
  orphans: 3;
  widows: 2;
  color: var(--amplify-colors-font-secondary);
}

.legal dt {
  font-size: var(--amplify-font-sizes-medium);
  margin: 12pt 0 3pt 0;
  font-weight: bold;
  color: var(--amplify-colors-font-secondary);
  -webkit-break-after: avoid;
  break-after: avoid;
}

.legal li {
  color: var(--amplify-colors-font-secondary);
  margin-top: 6pt;
}

.legal h1 {
  font-weight: bold;
  font-size: var(--amplify-font-sizes-xl);
  color: var(--amplify-colors-font-primary);
  margin: 0 0 4pt 0;
  padding: 8pt 0 4pt 0;
  -webkit-break-after: avoid;
  break-after: avoid;
}

.legal h2 {
  font-weight: bold;
  font-size: var(--amplify-font-sizes-large);
  color: var(--amplify-colors-font-secondary);
  margin: 0 0 4pt 0;
  padding: 8pt 0 4pt 0;
  border-bottom: 1px solid #4cc4dc;
  -webkit-break-after: avoid;
  break-after: avoid;
}

.legal h3 {
  font-weight: bold;
  font-size: var(--amplify-font-sizes-medium);
  color: var(--amplify-colors-font-primary) !important;
  margin: 15pt 0 6pt 0;
  -webkit-break-after: avoid;
  break-after: avoid;
}
