.Banner {
  background-color: var(--amplify-colors-brand-primary-100);
  width: 100%;
  width: stretch;
  height: 44px;
  position: absolute;
  top: 0;
  z-index: +1;
  margin: 0px 0px 0px 0px;
  padding: 2px 8px 2px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.TopBar {
  margin: 0px;
  padding: 0px;
  border-radius: 4px;
  pointer-events: auto;
}

.TopBar .menu li {
  list-style-type: none !important;
  padding: 0px 8px 0px 8px;
}

.fill-available {
  width: 100%;
  width: stretch;
  height: 100%;
  height: stretch;
}

.MainContainer {
  display: flex;
  flex-direction: row;
}

.Main {
  background-color: white;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
  height: 100%;
}

.LeftBar {
  min-width: 148px;
  margin: 0 0 0 0;
}

.LeftBar .menu {
  padding: 0 0 0 28px;
}

a {
  color: var(--amplify-colors-brand-secondary-80);
  text-decoration: none;
}
a:hover {
  color: var(--amplify-colors-brand-secondary-90);
  text-shadow: 0 0 1px black;
}
a:active {
  color: var(--amplify-colors-brand-secondary-100);
  text-shadow: 0 0 1px black;
}

.row ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0px 0px 0px 0px;
}

.column ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px;
  padding: 0 0 0 16px;
}

.menu {
  margin: 0px;
  padding: 0px;
}

.menu li {
  list-style-type: none;
  padding: var(--amplify-space-xxs);
}

.menu a {
  color: #4cc4dc;
  text-decoration: none;
}

@media screen and (min-width: 769px) {
  .TopBar {
    display: none !important;
  }
  .mapboxgl-ctrl-top-left .mapboxgl-ctrl, .maplibregl-ctrl-top-left .maplibregl-ctrl {
    margin-top: 2px !important;
  }
  .mapboxgl-map, .react-draggable {
    width: calc(100vw - 148px) !important;
  }
}

@media screen and (max-width: 768px) {
  .blaBla {
    display: none !important;
  }
  .LeftBar {
    display: none !important;
  }
  .maplibregl-ctrl-top-left {
    margin-top: 44px;
  }
}

@media screen {
  .MainContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

@media print {
  .print-logo {
    position: fixed;
    top: -0.35in; /* above margin */
  }
}

.federated-sign-in-button {
  background-color: var(--amplify-colors-brand-secondary-40);
  border-radius: var(--amplify-radii-small);
}

.amplify-field-group {
  background-color: var(--amplify-colors-background-secondary);
  border-radius: var(--amplify-radii-small);
}
